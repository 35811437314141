interface ExtractedImagesState {
    selectedImages: string[];
    addToselectedImages: (filename: string) => void;
    removeFromselectedImages: (filename: string) => void;
    resetSelectedImages: () => void;
    displayImageManuals: boolean
    setDisplayImageManuals: (value: boolean) => void;
}

export const createExtractedImagesSlice = (set: any, get: any) => ({
    ...createExtractedImageStates(set, get),
    ...createExtractedImageActions(set, get),
});


const createExtractedImageStates = (set: any, get: any): ExtractedImagesState => ({
    selectedImages: [],
    displayImageManuals: false,
    addToselectedImages: (filename: string) => set((state: any) => ({
        selectedImages: [...state.selectedImages, filename]
    })),
    removeFromselectedImages: (filename: string) => set((state: any) => ({
        selectedImages: state.selectedImages.filter((name: string) => name !== filename)
    })),
    resetSelectedImages: () => set({ selectedImages: [] }),
    setDisplayImageManuals: (value: boolean) => set({ displayImageManuals: value })
});

const createExtractedImageActions = (set: any, get: any) => ({
});