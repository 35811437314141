import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Card } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Amplify, Storage } from 'aws-amplify'
import './PdfFileUpload.scss';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useRecordEvent } from 'aws-rum-react';
import { usePdfUploadState } from '../../store';
import aws_config from '../../modified_aws-export';
import { IOptionType } from '../shared/models/common';

Amplify.configure(aws_config);


const PdfFileUpload: React.FC = () => {
    const { t } = useTranslation();
    const {
        uploadComplete,
        setUploadComplete,
        setUploadedFiles,
        setFileMap,
    } = usePdfUploadState();

    const [progress, setProgress] = useState(0);
    const [files, setFiles] = useState<File[]>([]);
    const [uploading, setUploading] = useState(false);
    const recordEvent = useRecordEvent();

    const RETRY_DELAY_MS = 5000;
    const MAX_RETRIES = 3;

    const dropzoneStyle: React.CSSProperties = {
        padding: '20px',
        border: '2px dashed #ccc',
        textAlign: 'center',
        opacity: uploading || uploadComplete ? 0.5 : 1,
        pointerEvents: uploading || uploadComplete ? 'none' : 'auto',
        height: '250px',
        backgroundColor: '#ffffff'
    };

    const uploadFile = async (file: File, index: number, fileProgress: number[], totalFiles: number, retries = MAX_RETRIES): Promise<string> => {
        const key = `special_tools_extraction/pdf_files/${file.name}`;
        try {
            const result = await Storage.put(key, file, {
                contentType: file.type,
                customPrefix: { public: "" },
                progressCallback(progress: any) {
                    if (progress.total > 0) {
                        fileProgress[index] = progress.loaded / progress.total;
                    } else {
                        fileProgress[index] = 0;
                    }
                    const totalProgress = fileProgress.reduce((acc, curr) => acc + curr, 0) / totalFiles;
                    setProgress(totalProgress * 100);
                }
            });

            const path = result.key;
            let { eTag } = await Storage.getProperties(path, {
                customPrefix: { public: "" }
            });

            const fileName = key.split("/")[2];
            eTag = eTag.slice(1, -1);
            setFileMap({ fileName, eTag });
            setUploadedFiles(file);

            return result.key;
        } catch (error) {
            if (retries > 0) {
                console.warn(`Upload failed. Retrying... (${MAX_RETRIES - retries + 1}/${MAX_RETRIES})`);
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY_MS));
                return uploadFile(file, index, fileProgress, totalFiles, retries - 1);
            } else {
                console.error('Upload failed after maximum retries:', error);
                recordEvent('PDFUploadError', { 'error': error });

                throw error;
            }
        }
    };

    const onUpload = useCallback(async (files: File[]) => {
        recordEvent('StartUploadingPdfEvent', { 'description': 'Extraction of special tools for uploaded PDF files was started' });
        try {
            setUploading(true);
            const fileProgress = new Array(files.length).fill(0);
            const uploadPromises = files.map((file, index) => uploadFile(file, index, fileProgress, files.length));
            await Promise.all(uploadPromises);
            setProgress(100);
            setUploadComplete(true);
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error uploading files:', error.message);
                toast.error(`Error uploading files: ${error.message}`, {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored"
                });
            } else {
                console.error('Unknown error occurred:', error);
                toast.error(`Unknown error occurred: ${error}`, {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored"
                });
            }
        } finally {
            setUploading(false);
        }
    }, []);

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }, [onUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
        },
        multiple: true,
        onDrop,
    });

    const customStyles = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    const handleFilesChange = (selectedOptions: IOptionType[]) => {
        const selectedFiles = selectedOptions.map(option => files.find(file => file.name === option.value));
        const updatedFiles = files.filter(file => selectedFiles.includes(file));
        setFiles(updatedFiles);
    };

    const customComponents = {
        DropdownIndicator: () => null,
    };


    const onClickUpload = async () => {
        await onUpload(files);
    }

    return (
        <div className="upload-pdf-container">
            <div className="page-header">
                <div className="page-title">{t("Manual.ManualFinder")}</div>
            </div>
            <div className="page-body d-flex justify-content-center align-items-center">
                <Card className="upload-pdf-card">
                    <Card.Body>
                        {!uploadComplete &&
                            <div>
                                <div {...getRootProps()} className="text-center p-4 border-3"
                                    style={dropzoneStyle}>
                                    <input {...getInputProps()} />
                                    {uploadComplete ? (
                                        <p>{t("PdfFileUpload.UploadSuccess")}</p>
                                    ) : (
                                        <p>{t("PdfFileUpload.UploadFile")}</p>
                                    )}

                                    <div className="mt-3">
                                        <Button variant="secondary"
                                            disabled={uploading || uploadComplete}>{t("Button.selectPdf")}</Button>
                                    </div>
                                </div>
                                <CreatableSelect
                                    isClearable
                                    className='card border-0 d-md-block mt-3'
                                    closeMenuOnSelect={false}
                                    options={files.map(file => ({ label: file.name, value: file.name }))}
                                    value={files.map(file => ({ label: file.name, value: file.name }))}
                                    placeholder={t('PdfFileUpload.SelectedFiles')}
                                    isSearchable
                                    isMulti
                                    onChange={(selectedOptions) => handleFilesChange(selectedOptions as IOptionType[])}
                                    styles={customStyles}
                                    noOptionsMessage={() => null}
                                    components={customComponents}
                                />
                                <div className="d-flex justify-content-center mt-3">
                                    <Button variant="secondary" onClick={onClickUpload}
                                        disabled={uploading || uploadComplete || files.length <= 0}>Upload </Button>
                                </div>
                            </div>
                        }
                        {uploading && (
                            <div className="text-center mt-3">
                                <ProgressBar now={progress} label={`${progress.toFixed(2)}%`} />
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};


export default PdfFileUpload;
