import { Amplify, Storage } from 'aws-amplify';
import aws_config from '../../modified_aws-export';
import { SetStateAction, useEffect, useState } from 'react';

import { ResultMetadata } from "./IResultMetadata";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useConfigState } from '../../store';
import { handleOemMatch, matchActions } from "../../api/backend";
import { DropFile } from "../DropZone/DropZone";
import { useRecordEvent } from 'aws-rum-react';
import { copyFilenameToClipboard } from '../../utils/manualUtils';

Amplify.configure(aws_config);

interface ItemResultsProps {
    score: number,
    elements: any[] | null;
    metadata: ResultMetadata;
    dropfile: DropFile,
    updateDisplayResults?: SetStateAction<any>;
}

const ItemResults: React.FC<ItemResultsProps> = ({ score, metadata, elements, dropfile }) => {
    const [img, setImg] = useState<string | undefined>(undefined)
    const [imgTA, setImgTA] = useState<string | undefined>(undefined)
    const { t } = useTranslation();
    const { showDistance } = useConfigState()
    const recordEvent = useRecordEvent();

    useEffect(() => {
        Storage.configure({
            aws_user_files_s3_bucket_region: aws_config.backend.region, // (required) - Amazon S3 bucket region
            aws_user_files_s3_bucket: aws_config.backend.bucket_name // (required) - Amazon S3 bucket name
        })
        var config = {
            level: 'public',
            customPrefix: { public: '' }
        };

        const t = async (config: any) => {
            try {
                let e = await Storage.get(`processed_images/${metadata.FileName}.png`, { ...config, validateObjectExistence: true });
                setImg(e);
                if (metadata.FileName.includes("OEM")) {
                    let e2 = await Storage.get(`processed_images/${metadata.FileName.replace('OEM', 'TA')}.png`, { ...config, validateObjectExistence: true });
                    setImgTA(e2)
                }
            } catch (error) {

            }
        }
        t(config)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const matchOemToSingleClick = () => {
        if (window.confirm(t("MatchFunctionality.MatchConfirmText"))) {
            copyFilenameToClipboard(metadata.FileName, t("CopyToClipboardMessage"))
            recordEvent('MatchSingleToOEM', {'description':'Match OEM image ' + dropfile.id + ' to single image ' + metadata.FileName});
            handleOemMatch(matchActions.match, metadata.FileName, t, "image_similarity_search/" + dropfile.id + ".png" || "", JSON.stringify(metadata))
        }
    }

    const unmatchOemClick = () => {
        if (window.confirm(t("MatchFunctionality.UnmatchConfirmText"))) {
            recordEvent('UnmatchOEM', {'description':'Unmatch OEM image ' + metadata.FileName});
            handleOemMatch(matchActions.unmatch, metadata.FileName, t)
        }
    }

    return (
        <div className="card card-body m-2 p-2" style={{ maxWidth: "12em", width: "12em" }}>
            <div>
                {showDistance ?
                    <span className="badge bg-secondary text-light"  style={{ position: "relative", left: "-1em", top: "-20px", width: "4em", float: "left" }}>{score.toFixed(3)}</span>
                    : null
                }
                
                {metadata.matchedBy === 'ImageMatcherTool' ?
                <>
                <span className="badge bg-warning text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title={t("MatchFunctionality.MatchedWithTool")} style={{ position: "relative", left: "1em", top: "-20px", width: "65px", float: "inline-start" }}>Matched</span>
                </>
                :
                <>
                </>
                }
                {elements ?
                    <span className="badge bg-primary text-light" style={{ position: "relative", right: "-1em", top: "-20px", width: "4em", float: "right" }}>{elements.length}</span>
                    : null}
            </div>
            <img src={img} className="card-img-top" alt={metadata.FileName} />
            {imgTA ?
                <img src={imgTA} className="card-img-top" alt={metadata.FileName.replace('OEM', 'TA')} />
                : null
            }
            <div className="card-body">
                {/* <p className="card-title">{getCardTitle(metadata)} </p> */}
                <p className="card-text">{t("brand")}: {metadata.Manufacturer}</p>
                <p className="card-text" onDoubleClick={() => copyFilenameToClipboard(metadata.FileName, t("CopyToClipboardMessage"))}>{metadata.FileName.replace('-OEM', '')}</p>
                {metadata.FileName.endsWith("-Single") ?
                    <button style={{marginTop: "-10px"}} className="btn btn-outline-secondary btn-sm" type="button" onClick={matchOemToSingleClick}>
                        {t("Button.match")}
                    </button>
                    : 
                    <></>
                }
                {metadata.matchedBy === 'ImageMatcherTool' ?
                    <button style={{marginTop: "-10px"}} className="btn btn-outline-secondary btn-sm" type="button" onClick={unmatchOemClick}>
                        {t("Button.unmatch")}
                    </button>
                :
                <></>
                }
                

                {/* <p className="card-text"><pre style={{height: "10em", overflow: "scroll"}}>{JSON.stringify(metadata,null,2)}</pre></p> */}
            </div>
            <div className="d-flex justify-content-center">
                {elements ?
                    <Link to={metadata.Manufacturer}>
                        <button className="btn btn-secondary bs-secondary">{t('Button.showProductCategorieResults').replace('{}', elements.length.toString())}</button>
                    </Link>
                    : null
                    // <a href={`https://web.tecalliance.net/tecdoc-internal/en/parts/${metadata.BrandNo}/${(metadata.ArtNo.startsWith(metadata.BrandNo) ? metadata.ArtNo.replace(metadata.BrandNo, '') : metadata.ArtNo)}/detail`} className="btn btn-secondary bs-secondary">{t('Button.open')}</a>
                }
            </div>
        </div>
    )
}

export default ItemResults