import {useLoaderData, useNavigate} from 'react-router-dom';
import ItemResults from '../ItemResults/ItemResults';
import {useEffect, useState} from 'react';
import {useConfigState, useResultsState} from '../../store';
import {useTranslation} from 'react-i18next';
import MetadataSelector from './MetadataSelector';
import aws_config from '../../modified_aws-export';
import {DropFile} from '../DropZone/DropZone';
import {getDropZoneResult, pineconeQuery} from '../../api/backend';
import MetadataFilter from './Filter';
import {useRecordEvent} from 'aws-rum-react';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';


interface ResultPageProps {
}

export const loader = async ({ params }: any) => {
  try {
    const dropfile = await getDropZoneResult(params.uuid);
    return [dropfile, params.single]
  } catch (error) {
    throw error;
  }
}


const ResultPage: React.FC<ResultPageProps> = () => {
  const { results, setResults, getResultCount } = useResultsState()
  let [current_file] = useLoaderData() as [DropFile, boolean]
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [displayResults, setDisplayResult] = useState<any>([])
  const { top_k, metadataSelector, showProductCategories } = useConfigState()
  const [filter, setFilter] = useState<object>({})
  const [filterResults, setFilterResults] = useState(true)
  const recordEvent = useRecordEvent();


  useEffect(() => {
    if (current_file.result) {
      let display: any = current_file.result
      setDisplayResult(display)
      setFilterResults(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProductCategories])

  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      setFilterResults(true);
      (async () => {
        await pineconeQuery(current_file, aws_config.aws_user_files_s3_bucket, "image_similarity_search/" + current_file.id + ".png", Number(top_k), setResults, recordEvent, JSON.stringify({ "$and": [filter, { "namespace": { "$in": ["OEM", "Single"] } }] }))
        let display: any = current_file.result
        setDisplayResult(display)
        setFilterResults(false)
      })();

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    if (current_file.result) {
      let display: any = current_file.result
      setDisplayResult(display)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])


  const handleBack = () => {
    getResultCount() === 1 ? navigate('/') : navigate('/image-matcher/results')
  };

  return (
    <>
      <div className="page-header">
        <Button
          variant="secondary"
          onClick={handleBack}
        >
          <FontAwesomeIcon icon={faAngleLeft} className="ms-2" />
          <span>{t("Button.back")}</span>
        </Button>
        <div className="page-title">{t("ExtractedImages.ImageMatcher")}</div>
      </div>
      <div className="page-body">
        <div className='text-center'>
          <img src={current_file.preview} alt='' className='rounded mx-auto d-block' />
          {filterResults ?
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {t('DropZone.loading')}
            </>
            :
            null
          }

        </div>

        <MetadataFilter setResults={setResults} dropfile={current_file}></MetadataFilter>

        {metadataSelector && current_file.result ? <MetadataSelector items={current_file.result} onChange={setFilter}></MetadataSelector> : null}

        <div className="row" style={{ marginTop: "20px" }}>
          {
            displayResults.length !== 0 ?
              displayResults.map((elem: any) => {
                return (
                  <div className="col justify-content-center d-flex" key={elem.id}>
                    <ItemResults score={elem.score as number} metadata={elem.metadata} elements={elem.elements} dropfile={current_file} ></ItemResults>
                  </div>
                )
              })
              :
              <div className="alert alert-warning justify-content-center d-flex" role="alert" style={{ width: "80%", margin: "auto" }}>
                {t("Filter.resultsNotFound")}
              </div>
          }
        </div>
      </div>
    </>
  );
}

export default ResultPage