import { useTranslation } from "react-i18next";
import { copyManualIdToClipboard, handleManualClick, ImageManual } from "../../utils/manualUtils";
import React, { useEffect, useState } from "react";
import { Badge, Button, Dropdown, DropdownButton, OverlayTrigger, Pagination, Popover, Table, Tooltip } from "react-bootstrap";
import "./ManualFinder.scss";
import { toast } from "react-toastify";
import { useRecordEvent } from "aws-rum-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown, faCopy, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import emptyStateLogo from "../../assets/empty-state.svg"

interface MatchingManualsImageProps {
    manuals: ImageManual[];
    setIsLoading: (loading: boolean) => void;
}

const MatchingManualsImages: React.FC<MatchingManualsImageProps> = ({ manuals, setIsLoading }) => {
    const { t } = useTranslation();
    const [isManualFeedbackProvided, setIsManualFeedbackProvided] = useState(false);
    const recordEvent = useRecordEvent();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        setIsManualFeedbackProvided(false);
    }, [manuals])

    const totalPages = Math.ceil(manuals.length / itemsPerPage);

    const onHandleManualClick = async (manual: ImageManual) => {
        handleManualClick(manual.manualId, setIsLoading, t);
    };

    const handleClickFeedbackButtons = (isFound: boolean) => {
        recordEvent("AutoManualSearchFeedbackButtonClicked", {
            status: isFound ? "Manual Found" : "Manual not found",
            foundManuals: manuals,
        });
        setIsManualFeedbackProvided(true);
        toast(t("ExtractedImages.FeedbackSubmitted"), { type: "success" });
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);
    const handleItemsPerPageChange = (value: string | null) => {
        if (value) {
            setItemsPerPage(Number(value));
            setCurrentPage(1);
        }
    };

    const paginatedManuals = manuals.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const renderCountFoundImagesTooltip = ({ ...props }) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('AutomaticImageSelection.CountFoundImagesTooltip')}
        </Tooltip>
    );

    const renderFileNames = (fileNames: string[]) => {
        if (!fileNames || fileNames.length === 0) {
            return "-";
        }

        const firstThree = fileNames.slice(0, 3);
        const remaining = fileNames.slice(3);

        return (
            <>
                {firstThree.map((filename, idx) => (
                    <Badge key={idx} className="filename-label me-1">
                        {filename}
                    </Badge>
                ))}
                {remaining.length > 0 && (
                    <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        overlay={
                            <Popover id="popover-filenames">
                                <Popover.Body>
                                    {remaining.map((filename, idx) => (
                                        <Badge key={idx} className="filename-label me-1">
                                            {filename}
                                        </Badge>
                                    ))}
                                </Popover.Body>
                            </Popover>
                        }
                        rootClose
                    >
                        <div className="position-relative d-inline-block">
                            <FontAwesomeIcon
                                icon={faAnglesDown}
                                className="dropdown-icon ms-1"
                                role="button"
                            />
                            <span
                                className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                                style={{ fontSize: "0.6em" }}
                            >
                                {remaining.length}
                            </span>
                        </div>
                    </OverlayTrigger>
                )}
            </>
        );
    };


    if (manuals.length === 0) {
        return (
            <div className="empty-state">
                <img src={emptyStateLogo} alt='empty-state' />
                <span>{t("AutomaticImageSelection.NoManualsFound")}</span>
            </div>
        );
    }

    return (
        <div className="manual-details-listgroup">
            <div className="d-flex justify-content-end pb-3">
                <Button
                    variant="danger"
                    onClick={() => handleClickFeedbackButtons(false)}
                    disabled={isManualFeedbackProvided}
                    className="me-2"
                >
                    {t("ExtractedImages.ManualNotFoundButton")}
                </Button>
                <Button
                    variant="success"
                    onClick={() => handleClickFeedbackButtons(true)}
                    disabled={isManualFeedbackProvided}
                >
                    {t("ExtractedImages.ManualFoundButton")}
                </Button>
            </div>

            <Table bordered hover responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>{t("Manual.ItemMpText")}</th>
                        <th>{t("Manual.ManualName")}</th>
                        <th>
                            <div className="d-flex flex-row gap-2 align-items-baseline">
                                {t("AutomaticImageSelection.CountFoundImages")}
                                <OverlayTrigger
                                    container={document.body}
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderCountFoundImagesTooltip}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </OverlayTrigger>
                            </div>
                        </th>
                        <th>{t("AutomaticImageSelection.FoundImages")}</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedManuals.map((manual, index) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex gap-2"
                                    onDoubleClick={() =>
                                        copyManualIdToClipboard(
                                            manual.manualId,
                                            t("SpecialTool.CopyIdClipboardMessage").replace("{}", manual.manualId)
                                        )
                                    }>
                                    {manual.manualId}
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        className="copy-icon"
                                        role="button"
                                        onClick={() =>
                                            copyManualIdToClipboard(
                                                manual.manualId,
                                                t("SpecialTool.CopyIdClipboardMessage").replace("{}", manual.manualId)
                                            )
                                        }
                                    />
                                </div>
                            </td>
                            <td>
                                {manual.itemMpText}
                            </td>
                            <td>
                                <a href="#" onClick={() => onHandleManualClick(manual)}>
                                    <p className="manual-name">{manual.manualName}</p>
                                </a>
                            </td>
                            <td>
                                {manual.imageFileNameCount} {t("AutomaticImageSelection.outof")} {manual.totalImageCount}
                            </td>
                            <td>{renderFileNames(manual.imageFileNamesFound)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div className="d-flex justify-content-center pagination-wrapper">
                <Pagination className="custom-pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
                <DropdownButton
                    title={`${itemsPerPage}`}
                    variant="secondary"
                    onSelect={handleItemsPerPageChange}
                >
                    {[5, 10, 15, 25, 50].map((option) => (
                        <Dropdown.Item key={option} eventKey={option}>
                            {option}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </div>
        </div>
    );
};

export default MatchingManualsImages;
