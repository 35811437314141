import React, { useEffect, useState } from "react";
import { Amplify, Storage } from "aws-amplify";
import aws_config from "../../modified_aws-export";
import DisplayMatchedImages from "./DisplayMatchedImages";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  OverlayTrigger,
} from "react-bootstrap";
import "./PdfImageExtractor.scss";
import { useExtractedImagesState, useFilterState } from "../../store";
import { useTranslation } from "react-i18next";
import { pineconeQuery } from "../../api/backend";
import {
  useConfigState,
  useResultsState,
  usePdfUploadState,
} from "../../store";
import { useRecordEvent } from "aws-rum-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faThumbsUp as solidThumbsUp,
  faThumbsDown as solidThumbsDown,
  faInfoCircle,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import {
  faThumbsUp as regularThumbsUp,
  faThumbsDown as regularThumbsDown,
} from "@fortawesome/free-regular-svg-icons";
import { handleSubmitImageFeedbackMutation } from "../../api/backend";
import { toast } from "react-toastify";
import Tooltip from "react-bootstrap/Tooltip";
import ManualDrawer from "./ManualDrawer";
import { Match, Image } from "../../utils/manualUtils";

Amplify.configure(aws_config);

interface ExtractedImagesProps {
}

export interface DropFile {
  fileobject: File;
  preview: string;
  id: string | null;
  result: any | null;
}

const ExtractedImages: React.FC<ExtractedImagesProps> = () => {
  const [parentImages, setParentImages] = useState<
    {
      parentImage: string;
      parentKeyName: string;
      matches: Match[];
      parentFile: DropFile;
      isUseful: boolean | null;
      feedbackProvided: boolean;
    }[]
  >([]);
  const {
    resetSelectedImages,
    displayImageManuals
  } = useExtractedImagesState();
  const { t } = useTranslation();
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const { top_k } = useConfigState();
  const { setResults } = useResultsState();
  const { extractionFinished, extractedImages } = usePdfUploadState();
  const recordEvent = useRecordEvent();
  const [reloadTaImages, setReloadTaImages] = useState(extractedImages.map(() => false));
  const { emptyFilter } = useFilterState()

  useEffect(() => {
    resetSelectedImages();
    const fetchImages = async () => {
      setIsLoadingImages(true);
      const getImage = async (key: string) => {
        let img = await Storage.get(`${key}`, {
          bucket: aws_config.aws_user_files_s3_bucket,
          region: aws_config.aws_user_files_s3_bucket_region,
          level: "public",
          customPrefix: { public: "" },
          validateObjectExistence: true,
        });
        return img;
      };

      const imageData = await Promise.all(
        extractedImages.map(async (element: Record<string, Image>) => {
          const keys = Object.keys(element);
          const parentKey = keys[0];
          const img = await getImage(parentKey);
          const response = await fetch(img);
          const blob = await response.blob();
          const id = parentKey.split("/").pop()?.split(".")[0] || null;
          const filename = "image_similarity_search/" + id + ".png";
          const new_uploadfile = new File([blob], filename, {
            type: "image/png",
          });
          const previewUrl = URL.createObjectURL(new_uploadfile);

          return {
            parentImage: img,
            parentFile: {
              fileobject: new_uploadfile,
              preview: previewUrl,
              id: id,
              result: null,
            },
            parentKeyName: parentKey,
            matches: element[parentKey].matches,
            isUseful: element[parentKey].is_useful,
            feedbackProvided: element[parentKey].feedback_provided,
          };
        })
      );

      setParentImages(imageData);
      setIsLoadingImages(false);
    };
    fetchImages().then();
  }, [extractedImages, resetSelectedImages]);

  const onRefresh = async (index: number, filename: string) => {
    setReloadTaImages((prev) => {
      const updated = [...prev];
      updated[index] = true;
      return updated;
    });
    const dropfile = parentImages[index].parentFile;
    var result = await pineconeQuery(
      dropfile,
      aws_config.aws_user_files_s3_bucket,
      dropfile.fileobject?.name as string,
      Number(3),
      setResults,
      recordEvent,
      JSON.stringify({ namespace: { $in: ["OEM", "Single"] } }),
      true
    );
    setReloadTaImages((prev) => {
      const updated = [...prev];
      updated[index] = false;
      return updated;
    });

    setParentImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index].matches = result;
      return updatedImages;
    });
  };

  const createDropFileObject = async (fileName: string, index: number) => {
    emptyFilter();
    const dropfile = parentImages[index].parentFile;
    await pineconeQuery(
      dropfile,
      aws_config.aws_user_files_s3_bucket,
      dropfile.fileobject?.name as string,
      Number(top_k),
      setResults,
      recordEvent,
      JSON.stringify({ namespace: { $in: ["OEM", "Single"] } })
    );
    window.open(
      `/image-matcher/results/${dropfile.id}?openInNewTab=true`,
      "_blank"
    );
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("ExtractedImages.FeebackToolTipText")}
    </Tooltip>
  );

  const handleFeedback = async (isUseful: boolean, id: any) => {
    const index = parentImages.findIndex((image) => image.parentFile.id === id);
    if (index !== -1) {
      const isSuccess = await handleSubmitImageFeedbackMutation(
        parentImages[index].parentFile.id,
        aws_config.aws_user_files_s3_bucket,
        parentImages[index].parentKeyName,
        isUseful
      );

      if (isSuccess) {
        setParentImages((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index].isUseful = isUseful;
          updatedImages[index].feedbackProvided = true;

          // Sort images by rating after updating
          return updatedImages.sort((a, b) => {
            if (a.isUseful === b.isUseful) return 0; // If both are the same, maintain order
            if (a.isUseful === true) return -1; // Move true to the front
            if (b.isUseful === true) return 1; // Move true to the front
            if (a.isUseful === null) return -1; // Move null before false
            if (b.isUseful === null) return 1; // Move null before false
            return 0; // If both are false
          });
        });

        toast(t("ExtractedImages.FeedbackSubmitted"), { type: "success" });
      } else {
        toast(t("ExtractedImages.FeedbackSubmittedError"), { type: "error" });
      }
    }
  };

  if (!extractionFinished || isLoadingImages) {
    return (
      <Container
        className="w-80 d-flex justify-content-center align-items-start"
        style={{ paddingTop: "20vh" }}
      >
        <Spinner animation="border" role="status" className="me-3">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <span>{t("ExtractedImages.ImagesExtractionLoading")}</span>
      </Container>
    );
  }
  return (
    <div className={`extracted-images-container d-flex flex-row ${displayImageManuals ? 'shift-left' : ''}`}>
      {displayImageManuals ? (<ManualDrawer />) : <></>}
      <div className="extracted-images">
        {parentImages.length > 0 ? (
          parentImages.map((imageSet, index) => (
            <Row key={imageSet.parentKeyName} className="mb-4">
              <Col md={4} className="d-flex align-items-stretch">
                <Card className="w-100 h-100 card-color d-flex flex-column image-card">
                  <Card.Body className="d-flex flex-column" style={{ flex: 1 }}>
                    <Row className="d-flex justify-content-between" style={{ flex: 1 }}>
                      <Col md={12} className="d-flex flex-column">
                        <Card.Title>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="flex-grow-1 text-center">
                              {t("ExtractedImages.PDFImage").replace(
                                "{}",
                                (index + 1).toString()
                              )}
                            </div>
                            <Button className="me-2 text-align-center d-flex align-items-center justify-content-center drop-file-button"
                              variant="secondary"
                              onClick={() =>
                                createDropFileObject(imageSet.parentImage, index)
                              }
                            >
                              <FontAwesomeIcon icon={faExternalLinkAlt} />{" "}
                            </Button>
                          </div>
                        </Card.Title>
                        <Card className="d-flex flex-column" style={{ flex: 1 }} >
                          <Card.Img className="image" src={imageSet.parentImage} alt={`Parent ${index}`} />
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                  <div className="d-flex justify-content-center align-items-center ">
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="info-icon mb-1 me-2"
                      />
                    </OverlayTrigger>
                    <Button
                      className="me-2 text-align-center d-flex align-items-center justify-content-center"
                      variant="secondary"
                      onClick={() => handleFeedback(true, imageSet.parentFile.id)}
                    >
                      <FontAwesomeIcon
                        icon={
                          imageSet.isUseful === true
                            ? solidThumbsUp
                            : regularThumbsUp
                        }
                        size="lg"
                      />
                    </Button>
                    <Button
                      className="me-2 text-align-center d-flex align-items-center justify-content-center"
                      variant="secondary"
                      onClick={() =>
                        handleFeedback(false, imageSet.parentFile.id)
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          imageSet.isUseful === false
                            ? solidThumbsDown
                            : regularThumbsDown
                        }
                        size="lg"
                      />
                    </Button>
                  </div>
                </Card>
              </Col>
              <Col md={8} className="d-flex align-items-stretch">
                <Card className="h-100 image-card">
                  <Card.Body>
                    <Card.Title>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="flex-grow-1">
                          {t("ExtractedImages.MatchedImages")}
                        </div>
                        {reloadTaImages[index] ? (
                          <Spinner animation="border" role="status" className="me-1" style={{ width: "1rem", height: "1rem" }} ></Spinner>
                        ) :
                          <>
                            <Button
                              className="me-2 text-align-center d-flex align-items-center justify-content-center"
                              variant="secondary"
                              onClick={() => onRefresh(index, imageSet.parentKeyName)}
                            ><FontAwesomeIcon icon={faRefresh} />{""}</Button></>
                        }
                      </div>
                    </Card.Title>
                    <Row className="d-flex justify-content-between">
                      <DisplayMatchedImages
                        oemImageName={imageSet.parentKeyName}
                        matches={imageSet.matches}
                      />
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))

        ) : (
          <div
            className="d-flex justify-content-center align-items-start"
            style={{ paddingTop: "20vh" }}
          >
            {t("ExtractedImages.NoImagesFoundMessage")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExtractedImages;
