import React, {useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import './DropZone.scss';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useFilterState, useResultsState} from '../../store';

interface DropZoneProps {
}


export interface DropFile {
    fileobject: File
    preview: string
    id: string | null
    result: any | null
}

const DropZone: React.FC<DropZoneProps> = () => {
    const [files, setFiles] = useState<DropFile[]>([]);
    const { t } = useTranslation();
    const { emptyResults, setResultCount, } = useResultsState()
    const acceptedFilesLenght = 50
    const { emptyFilter } = useFilterState()
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const openInNewTab = params.get('openInNewTab') === 'true';

    useEffect(() => {
        if (openInNewTab !== true) {
            emptyResults();
        }

        emptyFilter()
    }, [emptyResults])

    const setResults = (acceptedFiles: any) => {
        setResultCount(acceptedFiles.length)
        const dropFiles = acceptedFiles.map((file: File) => {
            let dropfile: DropFile = {
                fileobject: file,
                preview: URL.createObjectURL(file),
                result: null,
                id: null
            }
            return dropfile
        });
        setFiles(dropFiles);
        navigate("/image-matcher/results", { state: dropFiles, replace: true });
    }

    const handlePaste = (event: any) => {
        const items = event.clipboardData.items;
        let acceptedFiles = []
        if (items.length <= acceptedFilesLenght) {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.kind === 'file' && item.type.startsWith("image/")) {
                    acceptedFiles.push(item.getAsFile());
                }
            }
            setResults(acceptedFiles);
        }
        else {
            toast(t("DropZone.maxFilesExceeded").replace('{}', acceptedFilesLenght.toString()), { type: "error" })
        }

    };


    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        maxFiles: acceptedFilesLenght,
        onDrop: (acceptedFiles: any) => {
            setResults(acceptedFiles)
        },
        onDropRejected: (rejectedFiles: any) => {
            toast(t("DropZone.maxFilesExceeded").replace('{}', acceptedFilesLenght.toString()), { type: "error" })
        },
    });

    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);

    // const openInNewTab = getLocalItem('openInNewTab') === 'true';

    return (
        <>
            {(!files || files.length === 0) && (openInNewTab) ? (
                <>
                    <Outlet />
                </>
            ) :
                !files || files.length === 0 ? (
                    location.pathname.includes('config') ? (
                        <>
                            <Outlet />
                        </>
                    ) : (
                        <>
                            <div className="page-header">
                                <div className="page-title">{t("ExtractedImages.ImageMatcher")}</div>
                            </div>
                            <div className="page-body d-flex justify-content-center align-items-center">
                                <div className="card border-0">
                                    <div {...getRootProps()} className="dropzone">
                                        <input {...getInputProps()} />
                                        <p>{t('DropZone.text')}</p>
                                        <p>{t('DropZone.clipBoardText')}</p>
                                        <button className="btn btn-secondary mt-2" type="button">
                                            {t('Button.selectFile')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                ) : <>
                    <Outlet />
                </>
            }
        </>

    );

}

export default DropZone