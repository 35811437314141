import PropTypes from "prop-types";
import {useRef, useState} from "react";
import {Button, Nav, OverlayTrigger, Popover} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {useEventListener} from "usehooks-ts";

import "./AccountDropdown.scss";

interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
}

interface AccountDropdownProps {
  userInfo: UserInfo;
  handleSignOut: () => void;
}

const AccountDropdown: React.FC<AccountDropdownProps> = ({ userInfo, handleSignOut }) => {
  const { t } = useTranslation();
  const overlayRef = useRef(null);
  const location = useLocation();


  const [show, setShow] = useState(false);
  const formatNameInCircle = (firstName: string, lastName: string) => {
    return `${firstName?.charAt(0).toUpperCase()}${lastName
      ?.charAt(0)
      .toUpperCase()}`;
  };

  const formatFullName = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleClickOutside = (event: any) => {
    if (overlayRef.current && !(overlayRef.current as HTMLElement).contains(event.target)) {
      setShow(false);
    }
  };

  const handleUrlChange = () => {
    setShow(false);
  };

  /*
    Use the hook useEventListener here 
    because the props rootClose of overlayTrigger not working properly
    when use it with the props show 
    */
  useEventListener("click", handleClickOutside);
  useEventListener("popstate", handleUrlChange);

  const shouldShowLink = location.pathname.startsWith("/image-matcher");

  const popover = (
    <Popover>
      <Popover.Header>
        <div className="user-info-items">
          <div className="user-info-items__circle">
            {formatNameInCircle(userInfo?.firstName, userInfo?.lastName)}
          </div>
          <div className="user-info-items__info">
            <div className="user-info-item__username">{`${formatFullName(
              userInfo.firstName
            )} ${formatFullName(userInfo.lastName)}`}</div>
            <div className="user-info-item__useremail">{userInfo.email}</div>
          </div>
        </div>
      </Popover.Header>
      <Popover.Body>
      {shouldShowLink && (
          <Link
            className="dropdown-item-link"
            to="/image-matcher/config"
            onClick={() => setShow(false)}
          >
            {t("Config.nav_name")}
          </Link>
        )}
        <Nav.Link className="dropdown-item-nav-link">
          <div onClick={handleSignOut}>
            <span>{t("Logout")}</span> <FontAwesomeIcon icon={faSignOutAlt} />
          </div>
        </Nav.Link>
      </Popover.Body>
    </Popover>
  );

  return (
    <div ref={overlayRef}>
      <OverlayTrigger
        show={show}
        rootClose={true}
        trigger="click"
        placement="bottom-end"
        overlay={popover}
      >
        <Button id="button-dropdown" onClick={() => setShow(!show)}>
          {formatNameInCircle(userInfo?.firstName, userInfo?.lastName)}
        </Button>
      </OverlayTrigger>
    </div>
  );
};

AccountDropdown.propTypes = {
  userInfo: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  handleSignOut: PropTypes.func.isRequired,
};

export default AccountDropdown;
