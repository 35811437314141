import React, { useEffect, useState } from 'react';
import { Offcanvas, ListGroup, Spinner, Button, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useExtractedImagesState } from '../../store';
import { copyManualIdToClipboard, handleManualClick, ImageManual, ImageManualItem } from '../../utils/manualUtils';
import { toast } from 'react-toastify';
import { handleGetManualsByImages } from '../../api/backend';
import { useRecordEvent } from 'aws-rum-react';
import emptyStateLogo from "../../assets/empty-state.svg"
import "./PdfImageExtractor.scss";
import { useTranslation } from 'react-i18next';


interface IManualDrawerProps {
}

const ManualDrawer: React.FC<IManualDrawerProps> = () => {
    const {
        displayImageManuals,
        selectedImages,
        setDisplayImageManuals,
        resetSelectedImages,
    } = useExtractedImagesState();
    const { t } = useTranslation();
    const recordEvent = useRecordEvent();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [imageManuals, setImageManuals] = useState<ImageManual[]>([]);
    const [isFeedBackProvided, setIsFeedBackProvided] = useState(false);

    useEffect(() => {
        onGetManualsByImages();
        setIsFeedBackProvided(false);
    }, [selectedImages]);

    const handleCloseModal = () => {
        if (!isFeedBackProvided)
            return;
        resetSelectedImages();
        setDisplayImageManuals(false)
    };

    const handleClickFeedbackButtons = (
        isFound: boolean,
        manuals: ImageManual[]
    ) => {
        recordEvent("ManualFeedbackButtonsClicked", {
            status: isFound ? "Manual Found" : "Manual not found",
            selectedImages: selectedImages,
            foundManuals: manuals,
        });
        setIsFeedBackProvided(true);
        toast(t("ExtractedImages.FeedbackSubmitted"), { type: "success" });
    };

    const onHandleManualClick = async (manual: ImageManual) => {
        handleManualClick(manual.manualId, setIsLoading, t);
    };

    const onGetManualsByImages = async () => {
        setIsLoading(true)
        const manualResponse = await handleGetManualsByImages(selectedImages, 3);
        if (manualResponse.length > 0) {
            const mappedImageManuals: ImageManual[] = manualResponse
                .filter((item: ImageManualItem) => item)
                .map((item: ImageManualItem) => ({
                    manualName: item.manualName,
                    manualId: item.manualId,
                    itemMpText: item.itemMpText,
                    imageFileNameCount: item.imageFileNameCount,
                    imageFileNamesFound: item.imageFileNamesFound,
                    totalImageCount: item.totalImageCount,
                }));
            setImageManuals(mappedImageManuals);
        }
        setIsLoading(false)
    };

    return (
        <>
            <Offcanvas show={displayImageManuals} onHide={handleCloseModal} placement="end" backdrop={false} className="manual-drawer">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="d-flex justify-content-center">
                        {t("Manual.ManualDetails")}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>
                        <Offcanvas.Body>
                            {imageManuals.length > 0 ?
                                (
                                    <ListGroup>
                                        {imageManuals
                                            .sort((a, b) => b.imageFileNameCount - a.imageFileNameCount)
                                            .map((manual, index) => (
                                                <ListGroup.Item key={index} className="manual-item">
                                                    <Card className="manual-card">
                                                        <Card.Header className="d-flex flex-column align-items-center">
                                                            <Card.Title>
                                                                <div className="d-flex gap-2" onDoubleClick={() =>
                                                                    copyManualIdToClipboard(
                                                                        manual.manualId,
                                                                        t("SpecialTool.CopyIdClipboardMessage").replace("{}", manual.manualId)
                                                                    )
                                                                }>
                                                                    ID: {manual.manualId}
                                                                    <FontAwesomeIcon
                                                                        icon={faCopy}
                                                                        className="copy-icon"
                                                                        role="button"
                                                                        onClick={() =>
                                                                            copyManualIdToClipboard(
                                                                                manual.manualId,
                                                                                t("SpecialTool.CopyIdClipboardMessage").replace("{}", manual.manualId)
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </Card.Title>
                                                            <Card.Subtitle className="text-muted">
                                                                {`(${manual.imageFileNameCount}/${selectedImages.length} image(s) found)`}
                                                            </Card.Subtitle>
                                                        </Card.Header>
                                                        <Card.Body className="d-flex flex-column gap-2">
                                                            <div className="manual-card-content">
                                                                <span className="fw-bold">{manual.itemMpText}</span>
                                                            </div>
                                                            <div className="manual-card-content">
                                                                <span className="fw-bold hyper-link" onClick={() => onHandleManualClick(manual)}>{manual.manualName}</span>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </ListGroup.Item>
                                            ))}
                                    </ListGroup>) :
                                (
                                    <div className="empty-state">
                                        <img src={emptyStateLogo} alt='empty-state' />
                                        <span>{t("ExtractedImages.ManualsNotFound")}</span>
                                    </div>
                                )}
                        </Offcanvas.Body>
                        <div className="manual-drawer-footer p-3">
                            <div className="customer-feedback-buttons mb-2">
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip>{t("ExtractedImages.ManualButtonsTooltip")}</Tooltip>
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        className="info-icon mb-1 me-2"
                                    />
                                </OverlayTrigger>
                                <Button
                                    variant="success"
                                    disabled={isFeedBackProvided}
                                    onClick={() => handleClickFeedbackButtons(true, imageManuals)}
                                    className="me-2"
                                >
                                    {t("ExtractedImages.ManualFoundButton")}
                                </Button>
                                <Button
                                    variant="outline-danger"
                                    disabled={isFeedBackProvided}
                                    onClick={() => handleClickFeedbackButtons(false, imageManuals)}
                                >
                                    {t("ExtractedImages.ManualNotFoundButton")}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </Offcanvas>
        </>
    );
};

export default ManualDrawer;