import { ImageManual, Manual, Image } from '../../utils/manualUtils'


export const createPdfFileUploadSlice = (set: any, get: any) => ({
    ...createPdfFileUploadStates(set, get),
    ...createPdfFileUploadActions(set, get),
});

export const getLocalItem = (item: string, default_value: any = '') => {
    let item_value = window.localStorage.getItem(item)
    if (item_value) {
        return item_value
    } else {
        return default_value
    }
}
export const setLocalItem = (item: string, value: any = '') => {
    window.localStorage.setItem(item, value);
}

const createPdfFileUploadStates = (set: any, get: any) => ({
    uploadComplete: false,
    fileMap: {},
    uploadedFiles: [],
    filesUploaded: false,
    extractionFinished:false,
    extractedImages: [],
    specialTools: [],
    manualsBasedOnSpecialTools : [],
    manualsBasedOnImages: [],

    setUploadComplete: (status: boolean) => set({ uploadComplete: status }),
    setExtractionFinished: (status: boolean) => set({ extractionFinished: status }),
    setFileMap: (fileMap: any) =>
        set((state: any) => ({
            fileMap: 
                {
                    [fileMap.fileName]: fileMap.eTag,
                    ...state.fileMap
                },
                
            
        })),

    setUploadedFiles: (file: File) =>
        set((state: any) => ({
            uploadedFiles: [
                file,
                ...state.uploadedFiles
            ]
        })),
    clearFileMap: () => {
            set({ fileMap: {} });
        },
    
    clearUploadedFiles: () => {
            set({ uploadedFiles: [] });
        },
    setSpecialTools: (tools: string[]) => set({ specialTools: tools }),
    setExtractedImages: (images: Record<string, Image>[]) =>
            set({ extractedImages: images }),
    setManualsBasedOnSpecialTools: (manual: Manual[]) => set({ manualsBasedOnSpecialTools: manual }),
    setManualsBasedOnImages: (manual: ImageManual[]) => set({ manualsBasedOnImages: manual }),
});

const createPdfFileUploadActions = (set: any, get: any) => ({
});