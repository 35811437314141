import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {
  Badge,
  Button,
  Container,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Pagination,
  Popover,
  Table
} from 'react-bootstrap';
import './ManualFinder.scss';
import {copyManualIdToClipboard, handleManualClick, Manual} from '../../utils/manualUtils'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAnglesDown, faCopy} from '@fortawesome/free-solid-svg-icons';


interface MatchingManualsProps {
  manuals: Manual[];
  setIsLoading: (loading: boolean) => void;
}

const MatchingManualsSpecialTools: React.FC<MatchingManualsProps> = ({ manuals, setIsLoading }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const totalPages = Math.ceil(manuals.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);
  const handleItemsPerPageChange = (value: string | null) => {
    if (value) {
      setItemsPerPage(Number(value));
      setCurrentPage(1);
    }
  };

  const paginatedManuals = manuals.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleDownload = () => {
    const csvContent = convertToCSV(manuals);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "matchingManuals.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (data: Manual[]) => {
    const headers = [
      "ManualId",
      "ManualName",
      "ItemMpText",
      "MatchedTools",
      "AdditionalTools",
      "MissingTools",
    ];
    const rows = data.map((manual) => [
      manual.manualId,
      manual.manualName.replace(";", ","),
      manual.itemMpText,
      manual.matchedTools.join(", "),
      manual.additionalTools.join(", "),
      manual.missingTools.join(", "),
    ]);

    const csvRows = [
      headers.join(";"),
      ...rows.map((row) => row.join(";")),
    ];

    return csvRows.join("\n");
  };


  const onHandleManualClick = async (manual: Manual) => {
    handleManualClick(manual.manualId, setIsLoading, t);
  }

  const renderTools = (tools: string[], labelClass: string) => {
    if (!tools || tools.length === 0 || tools[0] === "") {
      return "-";
    }

    const firstThree = tools.slice(0, 3);
    const remaining = tools.slice(3);

    return (
      <>
        {firstThree.map((tool, idx) => (
          <Badge key={idx} className={`${labelClass} me-1`}>
            {tool}
          </Badge>
        ))}
        {remaining.length > 0 && (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover id="popover-tools">
                <Popover.Body>
                  {remaining.map((tool, idx) => (
                    <Badge key={idx} className={`${labelClass} me-1`}>
                      {tool}
                    </Badge>
                  ))}
                </Popover.Body>
              </Popover>
            }
            rootClose
          >
            <div className="position-relative d-inline-block">
              <FontAwesomeIcon
                icon={faAnglesDown}
                className="dropdown-icon ms-1"
                role="button"
              />
              <span
                className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                style={{ fontSize: "0.6em" }}
              >
                {remaining.length}
              </span>
            </div>
          </OverlayTrigger>
        )}
      </>
    );
  };

  if (manuals.length <= 0) {
    return (
      <Container className="w-80 d-flex justify-content-center align-items-start" style={{ paddingTop: '20vh' }}>
        <span>{t("SpecialTool.NoMatchingManuals")}</span>
      </Container>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          variant="secondary"
          onClick={handleDownload}
        >
          {t("SpecialTool.DownloadButton")}
        </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>{t("Manual.ItemMpText")}</th>
            <th>{t("Manual.ManualName")}</th>
            <th>{t("SpecialTool.MatchedTools")}</th>
            <th>{t("SpecialTool.AdditionalTools")}</th>
            <th>{t("SpecialTool.MissingTools")}</th>
          </tr>
        </thead>
        <tbody>
          {paginatedManuals.map((manual, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex gap-2"
                  onDoubleClick={() =>
                    copyManualIdToClipboard(
                      manual.manualId,
                      t("SpecialTool.CopyIdClipboardMessage").replace("{}", manual.manualId)
                    )
                  }>
                  {manual.manualId}
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="copy-icon"
                    role="button"
                    onClick={() =>
                      copyManualIdToClipboard(
                        manual.manualId,
                        t("SpecialTool.CopyIdClipboardMessage").replace("{}", manual.manualId)
                      )
                    }
                  />
                </div>
              </td>
              <td>
                {manual.itemMpText && (
                  manual.itemMpText
                )}
              </td>
              <td>
                {manual.manualName && (
                  <a href="#" onClick={() => onHandleManualClick(manual)}>
                    {manual.manualName}
                  </a>
                )}
              </td>
              <td>{renderTools(manual.matchedTools, "matched-tool-label")}</td>
              <td>{renderTools(manual.additionalTools, "additional-tool-label")}</td>
              <td>{renderTools(manual.missingTools, "notfound-tool-label")}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center pagination-wrapper">
        <Pagination className="custom-pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
        <DropdownButton
          title={`${itemsPerPage}`}
          variant="secondary"
          onSelect={handleItemsPerPageChange}
        >
          {[5, 10, 15, 25, 50].map((option) => (
            <Dropdown.Item key={option} eventKey={option}>
              {option}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    </>
  );
};

export default MatchingManualsSpecialTools