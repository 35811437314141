import React from "react";
import MatchingManualsSpecialTools from "./MatchingManualSpecialTools";
import ExtractedImages from "../PdfImageExtractor/ExtractedImages";
import { usePdfUploadState } from "../../store/hooks";
import MatchingManualsImages from "./MatchingManualImages";
import { ExtractedItem } from "../shared/models/constants";
import { Spinner } from "react-bootstrap";

interface SpecialToolProps {
    extractType: string;
    isLoading: boolean;
    setIsLoading: (loading: boolean) => void;
}

const ManualFinder: React.FC<SpecialToolProps> = ({
    extractType,
    isLoading,
    setIsLoading
}) => {
    const {
        manualsBasedOnSpecialTools,
        manualsBasedOnImages,
    } = usePdfUploadState()

    return (
        <>
            {isLoading && (
                <div className="loading-overlay">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
            {extractType === ExtractedItem.ImagesManual ? (
                <ExtractedImages />
            ) : extractType === ExtractedItem.ImagesAutomatic ? (
                <MatchingManualsImages manuals={manualsBasedOnImages} setIsLoading={setIsLoading} />
            ) : (
                <MatchingManualsSpecialTools manuals={manualsBasedOnSpecialTools} setIsLoading={setIsLoading} />
            )}
        </>
    );
};

export default ManualFinder;
